<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY REVENUE REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              v-model="type_of_bank"
              class="mx-2"
              dense
              outlined
              label="Purpose"
              :items="['ALL','TRUST FUND','EXPENSE','RESERVE FUND','INVESTMENT','REMITTANCE']"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Current','Non Current']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="option_items.length>0">
            <v-select
              v-model="option"
              class="mx-2"
              dense
              outlined
              label="Option"
              :items="option_items"
              :rules="rules.combobox_rule"
              @change="selected_option"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-form>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="data_items"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:top v-if="data_items.length>0">
          <h2 v-if="!saving_data" class="mx-2">PRINT
            <v-icon
              class="mr-2"
              color="success"
              @click="print_data"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </h2>

          <v-progress-circular
            :size=50
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.bank_code }}
            </td>
            <td>
              {{item.bank_balance_history===0?'': formatPrice(item.bank_balance_history) }}
            </td>
            <td>
              {{ item.total_deposit===0?'':formatPrice(item.total_deposit) }}
            </td>
            <td>
              {{ item.total_withdraw===0?'':formatPrice(item.total_withdraw) }}
            </td>
            <td>
              {{ item.balance===0?'':formatPrice(item.balance) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      option_items: [],
      category_items: [],
      month_of_items: [],
      data_items: [],
      category_id: '',
      option: '',
      alert_message: '',
      selection: '',
      type_of_bank: '',
      month_of_id: '',
      total_amount: '0.00',
      headers: [
        {text: 'Bank Code', value: 'date_of_deposit', sortable: false},
        {text: 'Last Month Balance', value: 'last_name', sortable: false},
        {text: 'Total Deposit', value: 'last_name', sortable: false},
        {text: 'Total Withdrawal', value: 'last_name', sortable: false},
        {text: 'Remaining Balance', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_transaction', ['initialization_bank_transaction', 'monthly_income']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_option() {
        await this.save_request()
      },
      async selected_selection() {
        this.data_items = []
        this.option_items = []
        this.category_id = ''
        if (this.selection != '') {
          this.initialization_bank_transaction({
            is_current: this.selection === 'Current' ? 1 : 0,
            month_of_id: this.month_of_id
          })
            .then(response => {
              this.category_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          await this.save_request()
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.data_items = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        if (this.$refs.form.validate()) {
          this.monthly_income({
            month_of_id: this.month_of_id,
            category_id: this.category_id,
            option: this.option,
            type_of_bank: this.type_of_bank,
          })
            .then(response => {
              this.data_items = response.data
              this.saving_data = false
              this.alert = false
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async selected_category(value) {
        this.data_items = []
        this.option_items = []
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          switch (this.category_items[index].category) {
            case "Damayan":
              this.option_items = ['All', 'DAM']
              break
            case "Chapel":
              this.option_items = ['All', 'SERV']
              break
            case "HO":
              this.option_items = ['All']
              break
          }
          this.option = 'All'
        }
        await this.save_request()
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []

          var title_print = 'MONTHLY REVENUE'

          if (this.category_id === 2 && this.option != 'All') {
            title_print = 'MONTHLY ' + this.option + ' REPORT'
            if (this.option === 'EXPENSE') {
              title_print = 'MONTHLY EXPENDITURE REPORT'
            }
          }

          widths = [200, 70, 70, 70, 70, 70]
          payments_array.push([
            {text: 'Bank Code', alignment: 'left', style: 'main_info',},
            {text: 'Last Month Balance', alignment: 'left', style: 'main_info'},
            {text: 'Total Deposit', alignment: 'left', style: 'main_info'},
            {text: 'Total Withdrawal', alignment: 'left', style: 'main_info'},
            {text: 'Remaining Balance', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.forEach(function (item) {
            payments_array.push([
              {
                text: item.bank_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.bank_balance_history === 0 ? '' : (item.bank_balance_history / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.total_deposit === 0 ? '' : (item.total_deposit / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.total_withdraw === 0 ? '' : (item.total_withdraw / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.balance === 0 ? '' : (item.balance / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2', color: 'red', bold: true,
              },
            ])
          })
          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Category: ',
                    {
                      text: this.category_items[this.category_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.category_id)].category + ' ( ' + this.selection + ' )',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Month of: ',
                    {
                      text: this.month_of_items[this.month_of_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.month_of_id)].month_of,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'HILARIO GENERAL ENTERPRISE',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: title_print,
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
